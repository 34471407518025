import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './PageBottomDescription.css';
import { frenchTextFix, IEventCategory } from '../../services/EventCategoryService';

type PageBottomDescriptionProps = {
    category?: string;
    categoryKey?: string;
};

const PageBottomDescription: React.FC<PageBottomDescriptionProps> = ({ category, categoryKey }) => {
    const { t } = useTranslation();
    // Selectors for Redux state
    const theme = useSelector((state: any) => state.config?.theme) || {}; // Extracted `theme` into a single variable
    const clientName = theme.name || '';
    const eventCategoryData: IEventCategory = useSelector((state: any) => state.config?.eventCategoryConfig) || { common: [] };
    const lang = useSelector((state: any) => state.currentLang);
    const additionalCategoryText = useSelector((state: any) =>
        state.categoryAdditionalText?.find((item: any) => item.category.toLowerCase() === (categoryKey?.toLowerCase() || ''))
    );
    const categoryText = categoryKey?.toLowerCase() || '';
    const isMetroCoUk = theme.client === 'metro-co-uk';
    const isIHeartDogs = theme.client === 'iheartdogs';
    const matchedEvent = eventCategoryData?.common?.find((eventData) =>
        [eventData.eventName.toLowerCase(), ...Object.values(eventData.eventNameLocales || {}).map((v) => v.toLowerCase())]
            .includes(categoryText)
    );
    const eventCategoryLocalisation = matchedEvent?.eventDescriptionLocales?.[lang] || matchedEvent?.eventDescription || '';
    const eventCategoryNameLocal = matchedEvent?.eventNameLocales?.[lang] || matchedEvent?.eventName || '';
    const title = eventCategoryNameLocal
        || (categoryText
                ? t('CATEGORY_BOTTOM_DESCRIPTION_TITLE', { category: categoryText })
                : t('HOME_BOTTOM_DESCRIPTION_TITLE', { clientName })
        );
    const text = eventCategoryLocalisation
        || (categoryText
                ? t('CATEGORY_BOTTOM_DESCRIPTION_TEXT', { category: categoryText, clientName })
                : t('HOME_BOTTOM_DESCRIPTION_TEXT', { clientName })
        );

    return (
        <div className={styles.container}>
            {categoryText !== 'all-games' && (
                <>
                    <h2
                        className={classNames(styles.title, {
                            [styles.titleCapitalized]: !isMetroCoUk && !isIHeartDogs
                        })}
                    >
                        {frenchTextFix(title)}
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                </>
            )}
            {additionalCategoryText && (
                <p dangerouslySetInnerHTML={{ __html: additionalCategoryText.text }} />
            )}
        </div>
    );
};

export default PageBottomDescription;
